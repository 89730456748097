import React, {
  isValidElement,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";
import { colorsUtils, Text } from "@d2c-ui-components-react";
import classNames from "classnames";
import get from "lodash/get";
import debounce from "lodash/debounce";
import { Maybe } from "@types";
import MainLayout from "components/MainLayout";
import DOMPurify from "isomorphic-dompurify";
import {
  SkeletonView as NextGenSkeletonView,
  NamedIcon,
} from "@fwd-dep/nextgen-ui-lib";
import { resolveAbsoluteUrlInParagraph } from "utils/route";
import I18nContext from "i18n/context/LanguageContext";
import { breakpoint } from "@d2c-ui-components-react";
import {
  CSTableContentProps,
  CenterAlign,
  TableColumnWidth,
  TableData,
  DesignSettings,
} from "./type";
import { transformCSTableData } from "./table.service";
import {
  COLUMN_MIN_DEFAULT_WIDTH,
  COLUMN_MAX_DEFAULT_WIDTH,
  DEFAULT_CELL_HEIGHT,
} from "./table.const";
import { useResponsive } from "@d2c-ui-components-react";

const TableInfoWrap = styled.div`
  padding: 0;
  margin-bottom: 48px;
  margin-top: 32px;

  ${breakpoint("md")`
    padding: 0 50px;
  `}
`;
const SectionDescription = styled.div`
  margin-top: 12px;
`;

const CellInner = styled.div`
  display: flex;
  align-items: stretch;
  padding: 14px 0;
  position: relative;
  z-index: 1;
  height: 100%;

  &:after {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 1px;
    background-color: #dbdfe1;
    left: 0;
  }
`;

const Footnote = styled.div`
  margin-top: 16px;
  padding-bottom: 0;
  padding-left: 31px;
  padding-right: 31px;

  ${breakpoint("md")`
    padding-left: 110px;
    padding-right: 110px;
  `}
  > div {
    width: 100%;
  }
`;

const PaddingWrap = styled.div`
  padding: 0;

  ${breakpoint("md")`
    padding: 0 80px;
  `}
`;

const OuterWrapper = styled.div<{
  tablePosition: string;
  designSettings: DesignSettings;
}>`
  display: flex;
  justify-content: ${(props) => props.tablePosition ?? "center"};
  width: 100%;
  position: relative;
  --table-border-radius: 10px;
  ${(props) =>
    props.designSettings === DesignSettings.VariantOne &&
    css`
      --table-border-color: ${colorsUtils.fwdOrange};
      --table-border-radius: 10px 0 10px 0;
    `}
  border-radius: var(--table-border-radius);
  max-width: 1288px;
  margin: 0 auto;

  table {
    thead,
    tbody,
    tfoot {
      vertical-align: middle !important;
    }
  }

  .navigation-button {
    position: absolute;
    width: 32px;
    height: 32px;
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    background-color: ${colorsUtils.fwdOrange};
    box-shadow: 1px 1px 5px 0 #00000061;

    ${breakpoint("lg")`
      display: flex;
  `}

    path {
      fill: #fff;
    }

    &--right {
      right: 10px;
      z-index: 500;
      ${breakpoint("md")`
        right: -50px;
      `}
    }
    &--left {
      left: 10px;
      z-index: 500;
      ${breakpoint("md")`
        left: -50px;
      `}
    }
    &--disabled {
      display: none;
    }
  }

  &.horizontal-scrollable:after {
    content: " ";
    display: block;
    background: linear-gradient(270deg, #00000026, #0000);
    position: absolute;
    width: 20px;
    border-radius: ${(props) =>
      props.designSettings === DesignSettings.VariantOne
        ? "0 0 10px 0"
        : "0 10px 10px 0"};
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 500;
  }

  tr:last-child {
    ${CellInner} {
      &:after {
        display: none;
      }
    }
  }
`;

const CellContent = styled.div`
  display: flex;
  width: 100%;
  padding: 0 20px;
  border-right: 1px solid ${colorsUtils.secondaryColorFwdGrey};
  flex-direction: column;
  p {
    margin: 0;
  }
`;

const MainTableWrapper = styled.div<{ stretch: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.stretch ? "100%" : "auto")};
  max-width: 100%;
  position: relative;
`;

const Table = styled.table<{
  firstColumnWidth?: number;
  headerHeight?: number;
  designSettings: DesignSettings;
}>`
  border-collapse: separate;
  border-spacing: 0;
  background-color: #fff;
  width: 100%;

  --cell-background: ${colorsUtils.fwdOrange};
  --cell-foreground: ${colorsUtils.fwdWhite};

  ${(props) =>
    props.designSettings === DesignSettings.VariantOne &&
    css`
      --cell-background: ${colorsUtils.fwdOrangeLight};
      --cell-foreground: ${colorsUtils.fwdOrange};

      thead th,
      thead td {
        border-bottom: 1px solid var(--table-border-color);
      }

      tbody tr:nth-child(even) {
        background-color: #f8f9f9;
      }
    `}

  &.table-fluid {
    width: 100%;
  }

  &.shadowed-column {
    /* tr:not(.section-row) td:first-of-type {
      box-shadow: 3px 0 4px 0 #00000038;
      clip-path: inset(0px -15px -1px 0px);
    } */

    &:after {
      display: block;
      content: " ";
      position: absolute;
      width: 15px;
      height: ${(props) => `calc(100% - ${props.headerHeight}px)`};
      background: linear-gradient(90deg, #00000026, #0000);
      right: -15px;
      top: ${(props) => `${props.headerHeight}px`};
    }

    td ${CellContent} {
      border-right: none !important;
    }
  }

  thead {
    tr,
    th {
      background-color: var(--cell-background);
      color: var(--cell-foreground);
    }

    th p {
      margin: 0;
      color: inherit !important;
    }

    ${CellContent} {
      word-break: normal;
    }

    ${CellInner} {
      border-bottom: none;
    }

    ${CellInner} * {
      font-weight: 700;
    }
  }

  td {
    min-height: 57px;
    max-height: 57px;
    height: 57px;

    &.left-right-margin-border ${CellInner}:after {
      width: calc(100% - 2rem);
      left: 0 !important;
      right: 0 !important;
      margin: auto;
    }

    &.left-margin-border ${CellInner}:after {
      width: ${(props) =>
          props.designSettings === DesignSettings.VariantOne
            ? "100%"
            : "calc(100% - 1rem)"};
      left: auto !important;
      right: 0 !important;
    }

    &.right-margin-border ${CellInner}:after {
      width: ${(props) =>
          props.designSettings === DesignSettings.VariantOne
            ? "100%"
            : "calc(100% - 1rem)"};
      left: 0 !important;
      right: auto !important;
    }

    &.no-bottom-border ${CellInner}:after {
      display: none;
    }
  }

  .section-row {
    background-color: ${colorsUtils.secondaryColorFwdGrey};
    position: relative;

    ${CellInner} {
      padding: 0;

      &:after {
        display: none;
      }
    }

    ${CellContent} {
      display: flex;
      justify-content: center;
      max-height: 57px;
      min-height: 57px;
      font-weight: 700;
      width: fit-content;
      position: sticky;
      left: 0;
    }

    &.section-row--sticky {
      position: absolute;
      width: 100%;
      z-index: 5;

      &.section-sticky {
        visibility: visible;
      }
    }

    td p {
      display: flex;
      align-items: center;
      margin: 0;
    }
  }

  tr {
    overflow: hidden;

    td,
    th {
      padding: 0;
    }
  }

  tr td:last-child ${CellContent}, tr th:last-child ${CellContent} {
    border-right: none;
  }
  tr .no-border-right {
    border-right: none !important;
  }
  tr .have-border-left {
    border-left: 1px solid ${colorsUtils.secondaryColorFwdGrey};
  }
`;

const TableFirstColumn = styled(Table)`
  background: transparent;
  height: 100%;

  tr td:last-child ${CellContent}, tr th:last-child ${CellContent} {
    border-right: 1px solid ${colorsUtils.secondaryColorFwdGrey};
  }

  .section-row ${CellContent} {
    border-right: none !important;
  }

  tr.last-section-row ${CellInner}:after {
    display: none;
  }

  .section-row,
  .section-row--sticky {
    color: transparent;
    background: transparent;

    td:first-of-type {
      background: transparent !important;
    }
  }
`;

const TableWrapper = styled.div<{
  stretch: boolean;
  designSettings: DesignSettings;
}>`
  --table-border-color: #eee;
  --table-border-radius: 10px;
  ${(props) =>
    props.designSettings === DesignSettings.VariantOne &&
    css`
      --table-border-color: ${colorsUtils.fwdOrange};
      --table-border-radius: 10px 0 10px 0;
    `}
  display: flex;
  width: ${(props) => (props.stretch ? "100%" : "fit-content")};
  max-width: 100%;
  border: 1px solid var(--table-border-color);
  border-radius: var(--table-border-radius);
  overflow: auto hidden;
  position: relative;

  .header-sticky {
    display: table;
    width: 100%;
    position: absolute !important;
    z-index: 2000;
    visibility: hidden;
  }

  &.sticky-mode {
    .header-sticky {
      visibility: visible;
    }
    .header-main {
      visibility: hidden;
    }
    ${TableFirstColumn} thead ${CellInner} {
      &:after {
        content: " ";
        display: block;
        height: 100%;
        width: 14px;
        position: absolute;
        background-color: #e87722;
        right: -14px;
        left: auto;
        top: 0;
      }
    }
  }

  &.sticky-column {
    tr td:first-of-type,
    tr th:first-of-type {
      left: 0;
      z-index: 1000;
    }

    tbody tr td:first-of-type {
      background-color: ${colorsUtils.fwdWhite};
    }

    tr th:first-of-type {
      background-color: ${colorsUtils.fwdOrange};
    }

    .section-row td:first-of-type {
      background-color: ${colorsUtils.secondaryColorFwdGrey};
    }
  }

  .header-sticky,
  .section-row--sticky {
    transition: top 0.2s linear;
  }
`;

const TableSection = styled.div``;
const ButtonRowText = styled.div`
  text-decoration: underline;
  font-weight: 700;
`;

const ButtonRow = styled.a<{ variant: "icon-left" | "icon-right" }>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;

  ${({ variant }) => variant === "icon-right" && css`
    flex-direction: row-reverse;
    svg {
      width: 24px;
      height: 24px;
      margin-right: 0 !important;
      margin-inline-start: auto;
    }
    ${ButtonRowText} {
      text-decoration: none;
    }
    `}


  svg {
    margin-right: 1rem;

    path {
      fill: ${colorsUtils.fwdOrange};
    }
  }

  &:hover {
    background: #e8772233;
  }
`;
interface TableProps {
  data: TableData;
  isLoading: boolean;
  headerVisible?: boolean;
  extraHeight?: number;
  showNavigation?: boolean;
  tablePosition?: string;
}
function getHeaderColumnWidth(
  columnIndex: number,
  columnWidths: TableColumnWidth[],
  defaultWidth: number = 0,
  factor: number = 1,
  cacheWidth: any = {}
): { width: string; customWidth: boolean } {
  const cachedWidth = cacheWidth[`${columnIndex}:${factor}`];

  if (!cachedWidth) {
    let width = columnWidths.find(
      (cw) => cw.columnIndex! === columnIndex
    )?.columnWidth;
    if (width) {
      if (factor > 1) {
        const widths = [width];
        for (let i = 1; i < factor; ++i) {
          const cWidth = columnWidths.find(
            (cw) => cw.columnIndex! === columnIndex + i
          )?.columnWidth;
          if (cWidth) {
            widths.push(cWidth);
          } else {
            widths.push(`${COLUMN_MIN_DEFAULT_WIDTH}px`);
          }
        }
        width = `calc(${widths.join(" + ")})`;
      }
      cacheWidth[`${columnIndex}:${factor}`] = { width, customWidth: true };
    } else {
      if (factor > 1) {
        const widths = [`${COLUMN_MAX_DEFAULT_WIDTH}px`];
        for (let i = 1; i < factor; ++i) {
          const cWidth = columnWidths.find(
            (cw) => cw.columnIndex! === columnIndex + i
          )?.columnWidth;
          if (cWidth) {
            widths.push(cWidth);
          } else {
            widths.push(`${COLUMN_MAX_DEFAULT_WIDTH}px`);
          }
        }
        width = `calc(${widths.join(" + ")})`;
        cacheWidth[`${columnIndex}:${factor}`] = {
          width,
          customWidth: true,
        };
      } else {
        cacheWidth[`${columnIndex}:${factor}`] = {
          width: Math.max(defaultWidth, COLUMN_MAX_DEFAULT_WIDTH * factor),
          customWidth: false,
        };
      }
    }
  } else {
    if (!cacheWidth[`${columnIndex}:${factor}`].customWidth) {
      cacheWidth[`${columnIndex}:${factor}`] = {
        width: Math.min(
          Math.max(cacheWidth[`${columnIndex}:${factor}`]?.width, defaultWidth),
          COLUMN_MAX_DEFAULT_WIDTH * factor
        ),
        customWidth: false,
      };
    }
  }
  const width = cacheWidth[`${columnIndex}:${factor}`];

  return width.customWidth
    ? { width: cacheWidth[`${columnIndex}:${factor}`].width, customWidth: true }
    : {
      width: `${cacheWidth[`${columnIndex}:${factor}`].width}px`,
      customWidth: false,
    };
}

function getColumnWidth(
  columnIndex: number,
  columnWidths: TableColumnWidth[],
  defaultWidth: number = 0,
  factor: number = 1,
  cacheWidth: any = {}
): { width: string; customWidth: boolean } {
  const cachedWidth = cacheWidth[columnIndex];

  if (!cachedWidth) {
    const width = columnWidths.find(
      (cw) => cw.columnIndex! === columnIndex
    )?.columnWidth;
    if (width) {
      cacheWidth[columnIndex] = { width, customWidth: true };
    } else {
      cacheWidth[columnIndex] = {
        width: Math.max(defaultWidth, COLUMN_MIN_DEFAULT_WIDTH * factor),
        customWidth: false,
      };
    }
  } else {
    if (!cacheWidth[columnIndex].customWidth) {
      cacheWidth[columnIndex] = {
        width: Math.min(
          Math.max(cacheWidth[columnIndex].width, defaultWidth),
          COLUMN_MAX_DEFAULT_WIDTH * factor
        ),
        customWidth: false,
      };
    }
  }
  const width = cacheWidth[columnIndex];

  return width.customWidth
    ? { width: cacheWidth[columnIndex].width, customWidth: true }
    : { width: `${cacheWidth[columnIndex].width}px`, customWidth: false };
}

interface TableBodyProps {
  refs: React.MutableRefObject<
    {
      inView: boolean;
      ref: Maybe<HTMLDivElement>;
    }[]
  >;
  inViewSectionIndex: number;
  sectionTop: number;
  cacheWidth: any;
  setFirstColWidth: any;
  data: TableData;
}
export const TableBody: React.FC<TableBodyProps> = (props) => {
  const i18nContext = useContext(I18nContext);
  const { refs, inViewSectionIndex, sectionTop, cacheWidth, setFirstColWidth } =
    props || {};
  const headerContents = get(props, "data.headerContents", []);

  return (
    <>
      {props?.data?.sections?.map((s, i) => {
        const tableSectionRef = refs.current?.[i]
          ? refs.current[i].ref?.querySelectorAll("table")[0]
          : null;

        return (
          <TableSection
            ref={(ref) => (refs.current[i] = { inView: false, ref })}
          >
            <Table designSettings={props.data.designSettings}>
              <tbody>
                {s.title ? (
                  <>
                    {!props?.data?.stickyRow ? (
                      <tr className="section-row">
                        <td colSpan={headerContents[0]?.length || 1}>
                          <CellInner>
                            <CellContent
                              style={{ width: "100%" }}
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(s.title),
                              }}
                            />
                          </CellInner>
                        </td>
                      </tr>
                    ) : null}
                    {props.data.stickyRow ? (
                      <tr
                        className={classNames({
                          "section-row": true,
                          "section-row--sticky": true,
                          "section-sticky": inViewSectionIndex === i,
                        })}
                        style={{
                          top: `${inViewSectionIndex === i ? sectionTop : 0}px`,
                          left: 0,
                          zIndex: 300,
                          position: "sticky",
                        }}
                      >
                        <td colSpan={headerContents[0]?.length || 1}>
                          <CellInner>
                            <CellContent
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(s.title),
                              }}
                            />
                          </CellInner>
                        </td>
                      </tr>
                    ) : null}
                  </>
                ) : null}
                {s.rows.map((r, i) => {
                  const offset = s.title && s.title.length > 0 ? 1 : 0;
                  if (s?.buttonRows?.[i]?.isButtonRow) {
                    return (
                      <tr>
                        <td
                          colSpan={headerContents[0]?.length}
                          className="left-right-margin-border"
                        >
                          <CellInner style={{ padding: "10px 0" }}>
                            <CellContent style={{ width: "100%" }}>
                              <ButtonRow
                                href={s?.buttonRows[i]?.link}
                                {...(() =>
                                  s.buttonRows[i].openInNewTab
                                    ? { target: "_blank" }
                                    : {})()}
                                variant={s?.buttonRows[i]?.variant}
                              >
                                <NamedIcon icon={s?.buttonRows[i]?.icon} />
                                <ButtonRowText>
                                  {s?.buttonRows[i]?.text}
                                </ButtonRowText>
                              </ButtonRow>
                            </CellContent>
                          </CellInner>
                        </td>
                      </tr>
                    );
                  }

                  return (
                    <tr>
                      {headerContents[0]?.map((_, hIdx) => {
                        if (s.rowSpanConfig?.[i]?.[hIdx] == -1) return "";
                        if (s.colSpanConfig?.[i]?.[hIdx] == -1) return "";
                        const centerAlign =
                          s.centerAlign?.[i]?.[hIdx] || CenterAlign.None;

                        let noRightBorder: boolean = false;
                        if (hIdx < headerContents[0]?.length - 1) {
                          if (
                            s.rowSpanConfig?.[i]?.[hIdx + 1] == -1 ||
                            (s.rowSpanConfig?.[i]?.[hIdx + 1] || 1) > 1
                          ) {
                            noRightBorder = true;
                          }
                        }

                        let haveLeftBorder: boolean = false;
                        if (
                          hIdx > 0 &&
                          (s.rowSpanConfig?.[i]?.[hIdx] || 1) > 1
                        ) {
                          haveLeftBorder = true;
                        }

                        let noBottomBorder: boolean = false;
                        if (
                          (s.rowSpanConfig?.[i]?.[hIdx] || 1) + i ==
                          s.rows.length
                        ) {
                          noBottomBorder = true;
                        }

                        let leftMarginBorder = hIdx === 0;
                        let rightMarginBorder =
                          hIdx === headerContents[0]?.length - 1;
                        if (
                          hIdx > 0 &&
                          hIdx < headerContents[0]?.length - 1
                        ) {
                          if (
                            (s.colSpanConfig?.[i]?.[hIdx] || 1) + hIdx ===
                            headerContents.length
                          ) {
                            rightMarginBorder = true;
                          }
                        }

                        let columnWidth = getColumnWidth(
                          hIdx,
                          get(props, "data.columnWidths", []),
                          tableSectionRef?.tBodies[0]?.children?.[i + offset]
                            ?.children?.[hIdx]?.clientWidth ||
                          COLUMN_MAX_DEFAULT_WIDTH,
                          1,
                          cacheWidth.current
                        );

                        let style: any = {
                          minWidth: columnWidth.width,
                          maxWidth: columnWidth.width,
                        };

                        let cellInnerStyle: any = {};

                        if (tableSectionRef?.tBodies[0]?.children[i + offset]) {
                          style.minHeight =
                            tableSectionRef?.tBodies[0]?.children[
                              i + offset
                            ].clientHeight;
                          cellInnerStyle.minHeight =
                            tableSectionRef?.tBodies[0]?.children[
                              i + offset
                            ].clientHeight;
                        }
                        const cellContentStyle: any = {};
                        if (columnWidth.customWidth) {
                          style.width = columnWidth.width;
                          // cellContentStyle.overflowX = "auto";
                          // cellContentStyle.whiteSpace = "nowrap";
                        }

                        if ((centerAlign & CenterAlign.Horizontal) !== 0) {
                          cellContentStyle.textAlign = "center";
                        }

                        if ((centerAlign & CenterAlign.Vertical) !== 0) {
                          cellContentStyle.justifyContent = "center";
                        }

                        if (hIdx === 0) {
                          return (
                            <td
                              ref={(ref) =>
                                setFirstColWidth(ref?.clientWidth || -1)
                              }
                              style={style}
                              rowSpan={s.rowSpanConfig?.[i]?.[hIdx] || 1}
                              colSpan={s.colSpanConfig?.[i]?.[hIdx] || 1}
                              className={classNames({
                                "left-margin-border": leftMarginBorder,
                                "right-margin-border": rightMarginBorder,
                                "no-bottom-border": noBottomBorder,
                              })}
                            >
                              <CellInner style={cellInnerStyle}>
                                {isValidElement(r[hIdx]) ? (
                                  <CellContent
                                    className={classNames({
                                      "no-border-right": noRightBorder,
                                      "have-border-left": haveLeftBorder,
                                    })}
                                    style={cellContentStyle}
                                  >
                                    {r[hIdx]}
                                  </CellContent>
                                ) : (
                                  <CellContent
                                    className={classNames({
                                      "no-border-right": noRightBorder,
                                      "have-border-left": haveLeftBorder,
                                    })}
                                    style={cellContentStyle}
                                    dangerouslySetInnerHTML={{
                                      __html: resolveAbsoluteUrlInParagraph(
                                        DOMPurify.sanitize(r[hIdx], {
                                          ADD_ATTR: ["target", "em", "strong"],
                                        }),
                                        i18nContext
                                      ),
                                    }}
                                  />
                                )}
                              </CellInner>
                            </td>
                          );
                        }
                        return (
                          <td
                            style={style}
                            rowSpan={s.rowSpanConfig?.[i]?.[hIdx] || 1}
                            colSpan={s.colSpanConfig?.[i]?.[hIdx] || 1}
                            className={classNames({
                              "left-margin-border": leftMarginBorder,
                              "right-margin-border": rightMarginBorder,
                              "no-bottom-border": noBottomBorder,
                            })}
                          >
                            <CellInner style={cellInnerStyle}>
                              {isValidElement(r[hIdx]) ? (
                                <CellContent
                                  className={classNames({
                                    "no-border-right": noRightBorder,
                                    "have-border-left": haveLeftBorder,
                                  })}
                                  style={cellContentStyle}
                                >
                                  {r[hIdx]}
                                </CellContent>
                              ) : (
                                <CellContent
                                  className={classNames({
                                    "no-border-right": noRightBorder,
                                    "have-border-left": haveLeftBorder,
                                  })}
                                  style={cellContentStyle}
                                  dangerouslySetInnerHTML={{
                                    __html: resolveAbsoluteUrlInParagraph(
                                      DOMPurify.sanitize(r[hIdx], {
                                        ADD_ATTR: ["target", "em", "strong"],
                                      }),
                                      i18nContext
                                    ),
                                  }}
                                />
                              )}
                            </CellInner>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </TableSection>
        );
      })}
    </>
  );
};

export const OneWebTableRaw: React.FC<TableProps> = (props) => {
  const { showNavigation = true } = props;

  const i18nContext = useContext(I18nContext);
  const isDesktop = useResponsive({ breakpoint: "md" });
  const [mainHeaderHeight, setMainHeaderHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentHeaderHeight, setCurrentHeaderHeight] = useState<any>(-1);
  const [navigationOffset, setNavigationOffset] = useState(0);
  const headerRef = useRef<HTMLTableElement>(null);
  const cacheWidth = useRef<any>({});
  const headerCacheWidth = useRef<any>({});
  const [containerScrollLeft, setContainerScrollLeft] = useState(0);
  const [firstColWidth, setFirstColWidth] = useState(-1);
  const [firstLoad, setFirstLoad] = useState(true);
  const [fixed, setFixed] = useState(false);
  const [headerTop, setHeaderTop] = useState(0);
  const [sectionTop, setSectionTop] = useState(0);
  const [inViewSectionIndex, setInViewSectionIndex] = useState(-1);
  const refs = useRef([] as { inView: boolean; ref: Maybe<HTMLDivElement> }[]);
  const Body = useMemo(() => {
    return (
      <TableBody
        refs={refs}
        inViewSectionIndex={inViewSectionIndex}
        sectionTop={sectionTop}
        cacheWidth={cacheWidth}
        setFirstColWidth={setFirstColWidth}
        data={props.data}
      />
    );
  }, [inViewSectionIndex, props.data, sectionTop]);
  useLayoutEffect(() => {
    if (firstLoad && !props.isLoading) {
      setFirstLoad(false);
    }
  }, [firstLoad, props.isLoading]);

  useLayoutEffect(() => {
    if (props.isLoading) setFirstLoad(true);
  }, [props.isLoading]);

  useLayoutEffect(() => {
    if (firstLoad) return;
    if (!headerRef.current) return;

    if (headerRef.current.clientHeight !== currentHeaderHeight) {
      if (!isDesktop) {
        if (currentHeaderHeight === -1) {
          setCurrentHeaderHeight(headerRef.current.clientHeight);
        } else {
          setCurrentHeaderHeight(
            Math.max(headerRef.current.clientHeight, currentHeaderHeight)
          );
        }
      } else {
        setCurrentHeaderHeight(
          Math.max(headerRef.current.clientHeight, currentHeaderHeight)
        );
      }
    }
  }, [
    currentHeaderHeight,
    headerRef.current?.clientHeight,
    isDesktop,
    firstLoad,
  ]);

  const renderStickyColumn = useCallback(() => {
    const tableData = props.data;
    const width = firstColWidth !== -1 ? `${firstColWidth}px` : "auto";
    const shadowed = (containerScrollLeft || 0) > 0;
    const headerCellContentStyle: any = {};

    const centerAlign = tableData?.headers?.[0]?.centerAlign;
    if ((centerAlign & CenterAlign.Horizontal) !== 0) {
      headerCellContentStyle.textAlign = "center";
    }

    if ((centerAlign & CenterAlign.Vertical) !== 0) {
      headerCellContentStyle.justifyContent = "center";
    }

    return (
      <TableFirstColumn
        designSettings={props.data.designSettings}
        firstColumnWidth={firstColWidth || 0}
        headerHeight={headerRef.current ? currentHeaderHeight : "auto"}
        style={{
          position: "sticky",
          left: 0,
          zIndex: 700,
          flexShrink: 0,
          width: width,
        }}
        className={classNames({ "shadowed-column": shadowed })}
      >
        <thead className="header-main">
          <tr>
            <td
              style={{
                width: width,
                maxWidth: width,
                minHeight: headerRef.current
                  ? `${currentHeaderHeight}px`
                  : "auto",
                height: headerRef.current ? `${currentHeaderHeight}px` : "auto",
              }}
              rowSpan={tableData?.headerColSpan?.[0]?.[0] || 1}
            >
              <CellInner
                style={{
                  width: width,
                  maxWidth: width,
                  minHeight: headerRef.current
                    ? `${currentHeaderHeight}px`
                    : "auto",
                }}
              >
                <CellContent
                  style={headerCellContentStyle}
                  dangerouslySetInnerHTML={{
                    __html: tableData?.headers[0]?.name,
                  }}
                />
              </CellInner>
            </td>
          </tr>
        </thead>
        <thead className="header-sticky" style={{ top: `${headerTop}px` }}>
          <tr>
            <td
              style={{
                width: width,
                maxWidth: width,
                minHeight: headerRef.current
                  ? `${currentHeaderHeight}px`
                  : "auto",
                height: headerRef.current ? `${currentHeaderHeight}px` : "auto",
              }}
            >
              <CellInner
                style={{
                  minHeight: headerRef.current
                    ? `${currentHeaderHeight}px`
                    : "auto",
                }}
              >
                <CellContent
                  style={headerCellContentStyle}
                  dangerouslySetInnerHTML={{
                    __html: tableData?.headers[0]?.name,
                  }}
                />
              </CellInner>
            </td>
          </tr>
        </thead>
        <tbody>
          {tableData?.sections?.map((s, i) => {
            const tableSectionRef: Maybe<HTMLTableElement> = refs.current?.[i]
              ? refs.current[i].ref?.querySelectorAll("table")[0]
              : null;
            const offset = s.title && s.title.length > 0 ? 1 : 0;
            const cellContentStyle: any = {};

            // const centerAlign = tableData?.headers?.[0]?.centerAlign;
            const centerAlign = s.centerAlign?.[i]?.[0] || CenterAlign.None;
            if ((centerAlign & CenterAlign.Horizontal) !== 0) {
              cellContentStyle.textAlign = "center";
            }

            if ((centerAlign & CenterAlign.Vertical) !== 0) {
              cellContentStyle.justifyContent = "center";
            }

            if (!tableSectionRef) return null;
            return (
              <>
                {s.title ? (
                  <>
                    {!props.data.stickyRow ? (
                      <tr className="section-row">
                        <td
                          style={{
                            minWidth: width,
                            maxWidth: width,
                            height:
                              tableSectionRef?.tBodies[0].children[0]
                                .clientHeight,
                            minHeight:
                              tableSectionRef?.tBodies[0].children[0]
                                .clientHeight,
                            position: "relative",
                          }}
                        >
                          <CellInner>
                            <CellContent
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(s.title),
                              }}
                            />
                          </CellInner>
                        </td>
                      </tr>
                    ) : null}
                    {props.data.stickyRow ? (
                      <tr
                        className={classNames({
                          "section-row": true,
                          "section-row--sticky": true,
                          "section-sticky": inViewSectionIndex === i,
                        })}
                        style={{
                          top: `${inViewSectionIndex === i ? sectionTop : 0}px`,
                          width: `calc(${width} + 30px)`,
                        }}
                      >
                        <td
                          style={{
                            height:
                              tableSectionRef?.tBodies?.[0].children?.[0]
                                ?.clientHeight,
                            minHeight:
                              tableSectionRef?.tBodies?.[0].children?.[0]
                                ?.clientHeight,
                          }}
                        >
                          <CellInner
                            style={{
                              whiteSpace: "nowrap",
                              position: "absolute",
                              top: 0,
                            }}
                          >
                            <CellContent
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(s.title),
                              }}
                            />
                          </CellInner>
                        </td>
                      </tr>
                    ) : null}
                  </>
                ) : null}
                {s.rows.map((r, i) => {
                  if (s.colSpanConfig?.[i]?.[0] === -1) return "";
                  if (s.rowSpanConfig?.[i]?.[0] === -1) return "";

                  let noBottomBorder: boolean = false;
                  if ((s.rowSpanConfig?.[i]?.[0] || 1) + i == s.rows.length) {
                    noBottomBorder = true;
                  }
                  return (
                    <tr
                      className={
                        s.rows.length - 1 === i ? "last-section-row" : ""
                      }
                    >
                      <td
                        style={{
                          minWidth: width,
                          maxWidth: width,
                          height:
                            tableSectionRef?.tBodies[0].children[i + offset]
                              ?.children[0].clientHeight,
                          minHeight:
                            tableSectionRef?.tBodies[0].children[i + offset]
                              ?.children[0].clientHeight,
                        }}
                        // rowSpan={s.rowSpanConfig?.[i]?.[0] || 1}
                        // colSpan={s.colSpanConfig?.[i]?.[0] || 1}
                        className={classNames({
                          "no-bottom-border": noBottomBorder,
                        })}
                      >
                        <CellInner
                          style={{
                            minHeight:
                              tableSectionRef?.tBodies[0].children[i + offset]
                                ?.clientHeight,
                          }}
                        >
                          <CellContent
                            style={cellContentStyle}
                            dangerouslySetInnerHTML={{ __html: r[0] }}
                          />
                        </CellInner>
                      </td>
                    </tr>
                  );
                })}
              </>
            );
          })}
        </tbody>
      </TableFirstColumn>
    );
  }, [
    containerScrollLeft,
    headerTop,
    inViewSectionIndex,
    props.data,
    sectionTop,
    firstColWidth,
    currentHeaderHeight,
  ]);

  const renderTableHeader = useCallback(() => {
    // the columns are already fixed width,
    // so, no need to set width for the header
    // const mainTableWidth =
    //   refs.current?.[0]?.ref?.querySelector("table")?.clientWidth;
    const headerContents = get(props, "data.headerContents", []);

    return (
      <Table ref={headerRef} designSettings={props.data.designSettings}>
        <thead className="header-main">
          {headerContents.map((headerRows, rowIdx) => {
            return (
              <tr>
                {headerRows.map((h, colIdx) => {
                  const headerCenterAlign = get(props, `data.headerCenterAlign.[${rowIdx}]`, []);
                  const headerColSpan = get(props, `data.headerColSpan.[${rowIdx}]`, []);
                  const headerRowSpan = get(props, `data.headerRowSpan.[${rowIdx}]`, []);
                  const firstItemHeaderContentsLength = headerContents[0] ? headerContents[0]?.length : 0;
                  if (
                    headerColSpan?.[colIdx] === -1 ||
                    headerRowSpan?.[colIdx] === -1
                  )
                    return "";
                  let noRightBorder: boolean = false;
                  if (colIdx < firstItemHeaderContentsLength - 1) {
                    if (
                      headerRowSpan?.[colIdx + 1] == -1 ||
                      (headerRowSpan?.[colIdx + 1] || 1) > 1
                    ) {
                      noRightBorder = true;
                    }
                  }

                  let haveLeftBorder: boolean = false;
                  if (colIdx > 0 && (headerRowSpan?.[colIdx] || 1) > 1) {
                    haveLeftBorder = true;
                  }

                  let noBottomBorder: boolean = false;
                  if (
                    (headerRowSpan?.[colIdx] || 1) + rowIdx ==
                    headerContents?.length
                  ) {
                    noBottomBorder = true;
                  }

                  let leftMarginBorder = colIdx === 0;
                  let rightMarginBorder =
                    colIdx === headerContents[0]?.length - 1;
                  if (
                    colIdx > 0 &&
                    colIdx < headerContents[0]?.length - 1
                  ) {
                    if (
                      (headerColSpan?.[colIdx] || 1) + colIdx ===
                      headerContents?.length
                    ) {
                      rightMarginBorder = true;
                    }
                  }

                  let width = { width: "auto", customWidth: false };
                  if (headerRef.current) {
                    width = getHeaderColumnWidth(
                      colIdx,
                      get(props, "data.columnWidths", []),
                      headerRef.current?.tHead
                        ? headerRef.current.tHead?.children?.[0]?.children?.[
                          colIdx
                        ]?.clientWidth
                        : COLUMN_MAX_DEFAULT_WIDTH,
                      headerColSpan?.[colIdx] || 1,
                      headerCacheWidth.current
                    );
                  }

                  let style: any = {
                    minWidth: width.width,
                    maxWidth: width.width,
                  };

                  const cellContentStyle: any = {};

                  const centerAlign = headerCenterAlign?.[colIdx];
                  if ((centerAlign & CenterAlign.Horizontal) !== 0) {
                    cellContentStyle.textAlign = "center";
                  }

                  if ((centerAlign & CenterAlign.Vertical) !== 0) {
                    cellContentStyle.justifyContent = "center";
                  }
                  if (width.customWidth) {
                    style.width = width.width;
                    // cellContentStyle.overflowX = "auto";
                    // cellContentStyle.whiteSpace = "nowrap";
                  }
                  return (
                    <td
                      style={style}
                      rowSpan={headerRowSpan?.[colIdx] || 1}
                      colSpan={headerColSpan?.[colIdx] || 1}
                      className={classNames({
                        "left-margin-border": leftMarginBorder,
                        "right-margin-border": rightMarginBorder,
                        "no-bottom-border": noBottomBorder,
                      })}
                    >
                      <CellInner style={{}}>
                        <CellContent
                          className={classNames({
                            "no-border-right": noRightBorder,
                            "have-border-left": haveLeftBorder,
                          })}
                          style={cellContentStyle}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(h),
                          }}
                        />
                      </CellInner>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <thead
          className="header-sticky"
          style={{
            top: `${headerTop}px`,
            zIndex: 600,
          }}
        >
          <tr>
            {headerContents[0]?.map((h, i) => {
              const headerCenterAlign = get(props, `data.headerCenterAlign.[0]`, []);
              const headerColSpan = get(props, `data.headerColSpan.[0]`, []);
              if (headerColSpan?.[i] === -1) return "";
              let width = { width: "auto", customWidth: false };
              if (headerRef.current) {
                width = getHeaderColumnWidth(
                  i,
                  get(props, "data.columnWidths", []),
                  headerRef.current?.tHead
                    ? headerRef.current.tHead?.children?.[0]?.children?.[i]
                      ?.clientWidth
                    : 0,
                  headerColSpan?.[i] || 1,
                  headerCacheWidth.current
                );
              }
              let style: any = {
                minWidth: width.width,
                maxWidth: width.width,
              };
              const cellContentStyle: any = {};

              const centerAlign = headerCenterAlign[i];
              if ((centerAlign & CenterAlign.Horizontal) !== 0) {
                cellContentStyle.textAlign = "center";
              }

              if ((centerAlign & CenterAlign.Vertical) !== 0) {
                cellContentStyle.justifyContent = "center";
              }
              if (width.customWidth) {
                style.width = width.width;
                // cellContentStyle.overflowX = "auto";
                // cellContentStyle.whiteSpace = "nowrap";
              }

              if (width.customWidth) {
                style.width = width.width;
                // cellContentStyle.overflowX = "auto";
                // cellContentStyle.whiteSpace = "nowrap";
              }
              return (
                <td style={style}>
                  <CellInner>
                    <CellContent
                      style={cellContentStyle}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(h),
                      }}
                    />
                  </CellInner>
                </td>
              );
            })}
          </tr>
        </thead>
      </Table>
    );
  }, [
    props.data.headerContents,
    props.data.headerCenterAlign,
    props.data.headerColSpan,
    props.data.headerRowSpan,
    props.data.columnWidths,
    headerTop,
  ]);

  useEffect(() => {
    if (!props?.data?.stickyRow) return;
    if (!props?.headerVisible) {
      setMainHeaderHeight(0);
    } else {
      const siteHeader = document?.querySelector("#header");
      let siteHeaderHeight = 0;

      if (siteHeader?.className) {
        siteHeaderHeight = siteHeader!.clientHeight;
      }
      setMainHeaderHeight(siteHeaderHeight);
    }
    const extraHeight = props?.extraHeight ?? 0;

    const containerElem = containerRef.current;
    if (!containerElem) {
      return;
    }
    const parentBoundingRect =
      containerElem?.parentElement?.getBoundingClientRect();
    if (!parentBoundingRect) return;

    if (containerElem && parentBoundingRect!.top < 0) {
      setHeaderTop(
        -parentBoundingRect!.top + mainHeaderHeight + extraHeight - 1
      );
      setFixed(true);
    } else {
      setFixed(false);
    }
  }, [
    mainHeaderHeight,
    props?.data?.stickyRow,
    props?.extraHeight,
    props?.headerVisible,
  ]);
  useEffect(() => {
    if (props?.isLoading) return;
    let containerElem = containerRef.current;

    const onScroll = function(): any {
      setContainerScrollLeft(containerElem?.scrollLeft || 0);
    };
    containerElem?.removeEventListener("scroll", onScroll);
    containerElem?.addEventListener("scroll", onScroll, { passive: true });

    return () => {
      containerElem?.removeEventListener("scroll", onScroll);
    };
  }, [props?.isLoading]);

  useEffect(() => {
    if (props?.isLoading) return;
    if (!props?.data.stickyRow) return;
    let containerElem = containerRef.current;
    const windowScroll = function(): any {
      const extraHeight = props?.extraHeight ?? 0;

      const parentBoundingRect =
        containerElem?.parentElement?.getBoundingClientRect();
      if (!parentBoundingRect) return;

      if (containerElem && parentBoundingRect!.top < 0) {
        setHeaderTop(
          -parentBoundingRect!.top + mainHeaderHeight + extraHeight - 1
        );
        setFixed(true);
      } else {
        setFixed(false);
      }
    };

    window?.removeEventListener("scroll", windowScroll);
    window?.addEventListener("scroll", windowScroll);
    return function(): any {
      window?.removeEventListener("scroll", windowScroll);
    };
  }, [
    mainHeaderHeight,
    props?.data.stickyRow,
    props?.extraHeight,
    props?.isLoading,
  ]);

  useEffect(() => {
    let containerElem = containerRef.current;
    const windowScroll = function(): any {
      if (!containerElem) return;
      const parentBoundingRect =
        containerElem?.parentElement?.getBoundingClientRect();
      if (!parentBoundingRect) return;

      if (
        parentBoundingRect?.y < 0 &&
        Math.abs(parentBoundingRect?.y) > parentBoundingRect?.height - 50
      )
        return;

      if (
        parentBoundingRect?.y < 0 &&
        Math.abs(parentBoundingRect?.y) > 35
      ) {
        setNavigationOffset(
          Math.abs(parentBoundingRect?.y) -
          15 +
          mainHeaderHeight
        );
      } else {
        setNavigationOffset(mainHeaderHeight);
      }
    };

    window?.removeEventListener("scroll", windowScroll);
    window?.addEventListener("scroll", windowScroll, { passive: true });
    return function(): any {
      window?.removeEventListener("scroll", windowScroll);
    };
  }, [mainHeaderHeight]);
  useEffect(() => {
    if (props?.isLoading) return;
    if (!props?.data?.stickyRow) return;
    const windowScroll = () => {
      if (!headerRef.current) return;

      const refLength = refs.current.length;
      let allOutOfView = true;
      for (let i = 0; i < refLength; i += 1) {
        const item = refs.current[i];
        if (!item.ref) continue;
        const boundingRect = item.ref.getBoundingClientRect();
        if (
          boundingRect.top <=
          (headerRef.current.clientHeight + mainHeaderHeight) * 0.6 &&
          boundingRect.bottom > (currentHeaderHeight || 0) + mainHeaderHeight
        ) {
          const currentInView = refs.current.find((ref) => ref.inView);

          if (currentInView && currentInView.ref !== item.ref) {
            currentInView.inView = false;
          }
          item.inView = true;
          const parentBoundingRect =
            containerRef.current?.getBoundingClientRect();
          setInViewSectionIndex(i);
          setSectionTop(
            -(parentBoundingRect?.top || 0) +
            (headerRef.current?.clientHeight || 0) +
            mainHeaderHeight -
            1 +
            (props.extraHeight ?? 0)
          );
          allOutOfView = false;
          break;
        }
      }

      if (allOutOfView) setInViewSectionIndex(-1);
    };
    const debouncedFunc = debounce(windowScroll, 10);

    window?.removeEventListener("scroll", debouncedFunc);
    window?.addEventListener("scroll", debouncedFunc, { passive: true });
    return () => {
      window?.removeEventListener("scroll", debouncedFunc);
    };
  }, [
    props.data.stickyRow,
    refs,
    props.isLoading,
    currentHeaderHeight,
    mainHeaderHeight,
    props.extraHeight,
  ]);

  return (
    <OuterWrapper
      designSettings={props.data.designSettings}
      className={classNames({
        "horizontal-scrollable": containerRef.current
          ? containerRef.current.scrollWidth -
          containerRef.current.clientWidth >
          containerScrollLeft
          : false,
      })}
      tablePosition={props.tablePosition || "center"}
    >
      {containerRef.current &&
        containerRef.current.scrollWidth != containerRef.current.clientWidth &&
        showNavigation && (
          <>
            <div
              className={`navigation-button navigation-button--left ${containerRef.current && containerScrollLeft === 0
                  ? "navigation-button--disabled"
                  : ""
                }`}
              onClick={() => {
                containerRef?.current?.scroll({
                  left: (containerRef?.current?.scrollLeft || 50) - 50,
                  behavior: "smooth",
                });
              }}
              style={{ top: `calc(35px + ${navigationOffset}px)` }}
            >
              <NamedIcon icon="chevron-left" />
            </div>
            <div
              className={`navigation-button navigation-button--right ${containerRef.current &&
                  containerRef.current.scrollWidth -
                  containerRef.current.clientWidth ===
                  containerScrollLeft
                  ? "navigation-button--disabled"
                  : ""
                }`}
              onClick={() => {
                containerRef?.current?.scroll({
                  left: (containerRef?.current?.scrollLeft || 0) + 50,
                  behavior: "smooth",
                });
              }}
              style={{ top: `calc(35px + ${navigationOffset}px)` }}
            >
              <NamedIcon icon="chevron-right" />
            </div>
          </>
        )}

      <TableWrapper
        stretch={props.data.stretchWidth}
        ref={containerRef}
        designSettings={props.data.designSettings}
        className={classNames({
          "os-host-flexbox": true,
          "sticky-column": props.data.stickyColumn,
          "sticky-row": props.data.stickyRow,
          "sticky-mode": fixed,
        })}
      >
        {props.data.stickyColumn ? renderStickyColumn() : null}
        <MainTableWrapper
          stretch={props.data.stretchWidth}
          style={{
            marginLeft: `${props.data.stickyColumn
                ? -(firstColWidth !== -1 ? firstColWidth : 0)
                : 0
              }px`,
          }}
        >
          {renderTableHeader()}
          {Body}
        </MainTableWrapper>
      </TableWrapper>
    </OuterWrapper>
  );
};

export const OneWebTable: React.FC<TableProps> = React.memo((props) => {
  if (props.isLoading) {
    return (
      <OuterWrapper
        tablePosition={props.tablePosition || "center"}
        designSettings={props.data?.designSettings || DesignSettings.Default}
      >
        <NextGenSkeletonView
          type="TableSkeleton"
          numOfRows={6}
          columns="25,25,25,25"
        />
      </OuterWrapper>
    );
  }

  return <OneWebTableRaw {...props} />;
});
const InnerWrapper = styled.div<{ paddingBottom: number; paddingTop?: number }>`
  padding: 96px 16px;
  padding-top: 32px;
  padding-bottom: ${(props) => `${props.paddingBottom}px`};

  [class*="MainLayout__ContentWrapper"] {
    width: 100% !important;
    max-width: unset !important;
    padding: 0;

    ${breakpoint("md")`
        padding: 0 32px;
      `}
  }
`;

function stringExists(val?: string): boolean {
  return (val || "").length > 0;
}
export const CSTableContent: React.FC<CSTableContentProps> = (props) => {
  const i18nContext = useContext(I18nContext);
  const tableData = useMemo(
    () =>
      props.data.table?.[0]
        ? transformCSTableData(props.data.table?.[0], i18nContext)
        : null,
    [i18nContext, props.data.table]
  );

  if (!tableData) return <div>Table Component: Missing data</div>;

  return (
    <MainLayout maxWidth="1400px">
      <InnerWrapper paddingBottom={props.data.footnote ? 0 : 32}>
        {(stringExists(props.data.title) ||
          stringExists(props.data.description)) && (
            <MainLayout maxWidth="1288px">
              <TableInfoWrap>
                {props.data.title && (
                  <div
                    style={(() =>
                      props.data.description
                        ? { marginBottom: 0 }
                        : { marginBottom: "46px" })()}
                  >
                    <Text
                      xsFontSize="31px"
                      lineHeight="39px"
                      mdFontSize="39px"
                      lgFontSize="39px"
                      mdLineHeight="49px"
                      fontWeight="bold"
                      textAlign="left"
                    >
                      {props.data.title}
                    </Text>
                  </div>
                )}
                {props.data.description && (
                  <SectionDescription
                    style={(() => (!props.data.title ? { marginTop: 0 } : {}))()}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          resolveAbsoluteUrlInParagraph(
                            props.data.description,
                            i18nContext
                          ),
                          {
                            ADD_ATTR: ["target", "em", "strong"],
                          }
                        ),
                      }}
                    />
                  </SectionDescription>
                )}
              </TableInfoWrap>
            </MainLayout>
          )}
        <PaddingWrap>
          <OneWebTable
            data={tableData}
            isLoading={false}
            headerVisible={!!props.data.headerVisible}
            tablePosition={tableData?.centerAlignment ? "center" : "flex-start"}
          />
        </PaddingWrap>
      </InnerWrapper>
      {props.data.footnote && (
        <Footnote>
          <MainLayout maxWidth="1288px">
            <SectionDescription>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    resolveAbsoluteUrlInParagraph(
                      props.data.footnote,
                      i18nContext
                    )
                  ),
                }}
              />
            </SectionDescription>
          </MainLayout>
        </Footnote>
      )}
    </MainLayout>
  );
};
