import get from "lodash/get";
import { replaceContentStackURL } from "@utils";
import { I18nContextData } from "i18n/context/LanguageContext";
import { resolveAbsoluteUrl, resolveAbsoluteUrlInParagraph } from "utils/route";
import { CSTableContentProps, TableData, CenterAlign, DesignSettings } from "./type";

function transformSingleRowHeader(
  csData: CSTableContentProps["data"]["table"][number]
) {
  const headersData = get(csData, "headers", []);

  return {
    headers:
    headersData.length > 0 ? headersData.map((h) => {
        let centerAlign = CenterAlign.None;
        if (h.vertical_center) {
          centerAlign |= CenterAlign.Vertical;
        }
        if (h.horizontal_center) {
          centerAlign |= CenterAlign.Horizontal;
        }

        return {
          name: h.content || "",
          centerAlign,
        };
      }) : [],
    ...(() => {
      const colToSpan: number[] = [];
      const centerAlign = [] as CenterAlign[];
      const content = [] as string[];

      const colNumbers = headersData.length || 0;
      for (let i = 0; i < colNumbers; i += 1) {
        colToSpan[i] = 1;
        centerAlign[i] = CenterAlign.None;
        content[i] = "";
      }

      let shouldSkip: Record<string, boolean> = {};
      for (let i = 0; i < colNumbers; i += 1) {
        if (shouldSkip[i]) continue;
        let colSpan = headersData[i].column_span;

        if (headersData[i].vertical_center) {
          centerAlign[i] |= CenterAlign.Vertical;
        }

        if (headersData[i].horizontal_center) {
          centerAlign[i] |= CenterAlign.Horizontal;
        }

        content[i] = headersData[i].content || "";

        if (colSpan > 0) {
          colToSpan[i] = colSpan;
          for (let colIdx = i + 1; colIdx < i + colSpan; colIdx += 1) {
            colToSpan[colIdx] = -1;
            shouldSkip[colIdx] = true;
          }
        }
      }
      return {
        headerColSpan: [colToSpan],
        headerCenterAlign: [centerAlign],
        headerContents: [content],
      };
    })(),
  };
}

function transformMultirowsHeader(
  csData: CSTableContentProps["data"]["table"][number]
) {
  const result = (function () {
    const headers = csData.header_multirows!;
    const headerRows = headers.rows;
    let rowToSpan: number[][] = [];
    let colToSpan: number[][] = [];
    let centerAlign: CenterAlign[][] = [];
    let content: string[][] = [];

    let rowNumbers = (headerRows || []).length;
    let colNumbers = (headerRows?.[0]?.cells || []).length;

    for (let i = 0; i < rowNumbers; i += 1) {
      rowToSpan[i] = [] as number[];
      colToSpan[i] = [] as number[];
      centerAlign[i] = [] as CenterAlign[];
      content[i] = [] as string[];
      for (let j = 0; j < colNumbers; j += 1) {
        rowToSpan[i][j] = 1;
        colToSpan[i][j] = 1;
        centerAlign[i][j] = CenterAlign.None;
        content[i][j] = "";
      }
    }

    let shouldSkip: Record<string, boolean> = {};
    for (let i = 0; i < rowNumbers; i += 1) {
      for (let j = 0; j < colNumbers; j += 1) {
        content[i][j] = headerRows?.[i]?.cells?.[j].content;

        if (headerRows?.[i]?.cells?.[j]?.vertical_center) {
          centerAlign[i][j] |= CenterAlign.Vertical;
        }
        if (headerRows?.[i]?.cells?.[j]?.horizontal_center) {
          centerAlign[i][j] |= CenterAlign.Horizontal;
        }

        if (shouldSkip[`${i},${j}`]) continue;
        let rowSpan = headerRows?.[i]?.cells?.[j]?.row_span;
        let colSpan = headerRows?.[i]?.cells?.[j]?.column_span;

        if (rowSpan > 0) {
          rowToSpan[i][j] = rowSpan;
          for (let rowIdx = i + 1; rowIdx < i + rowSpan; rowIdx += 1) {
            rowToSpan[rowIdx][j] = -1;
            shouldSkip[`${rowIdx},${j}`] = true;
          }
        }
        if (colSpan > 0) {
          colToSpan[i][j] = colSpan;
          for (let colIdx = j + 1; colIdx < j + colSpan; colIdx += 1) {
            colToSpan[i][colIdx] = -1;
            shouldSkip[`${i},${colIdx}`] = true;
          }
        }
        if (rowSpan > 0 && colSpan > 0) {
          for (let rowIdx = i + 1; rowIdx < i + rowSpan; rowIdx += 1) {
            for (let colIdx = j + 1; colIdx < j + colSpan; colIdx += 1) {
              colToSpan[rowIdx][colIdx] = -1;
              rowToSpan[rowIdx][colIdx] = -1;
              shouldSkip[`${rowIdx},${colIdx}`] = true;
            }
          }
        }
      }
    }

    return {
      headers: [],
      headerContents: content,
      headerRowSpan: rowToSpan,
      headerColSpan: colToSpan,
      headerCenterAlign: centerAlign,
    };
  })();
  return result;
}

function transformColumnWidths(
  csData: CSTableContentProps["data"]["table"][number]
) {
  return {
    columnWidths:
      csData?.headers
        ?.map((h, i) => ({
          columnIndex: i,
          columnWidth: h?.column_width || null,
        }))
        ?.filter((c) => c.columnWidth !== null) || [],
  };
}

function transformHeaders(
  csData: CSTableContentProps["data"]["table"][number]
) {
  if ((csData.header_multirows?.rows || []).length > 0) {
    return transformMultirowsHeader(csData);
  }
  return transformSingleRowHeader(csData);
}

export function transformCSTableData(
  csData: CSTableContentProps["data"]["table"][number],
  i18nContext: I18nContextData
): TableData {
  return {
    designSettings: csData.design_settings || DesignSettings.Default,
    centerAlignment: csData.center_alignment,
    stretchWidth: csData.stretch_width || false,
    stickyColumn: csData.use_sticky_column || false,
    stickyRow: csData.use_sticky_row || false,
    ...transformHeaders(csData),
    ...transformColumnWidths(csData),
    sections:
      csData.sections?.map((section) => ({
        title: section?.section_title,
        ...(() => {
          let rowToSpan: any = [];
          let colToSpan: any = [];
          let centerAlign: any = [];
          let buttonRows: {
            isButtonRow: boolean;
            icon: string;
            link: string;
            text: string;
            variant: "icon-left" | "icon-right";
            openInNewTab: boolean;
          }[] = [];

          let rowNumbers = (section.rows || []).length;
          let colNumbers = (section.rows?.[0]?.columns || []).length;

          for (let i = 0; i < rowNumbers; i += 1) {
            rowToSpan[i] = [] as number[];
            colToSpan[i] = [] as number[];
            centerAlign[i] = [] as CenterAlign[];
            for (let j = 0; j < colNumbers; j += 1) {
              rowToSpan[i][j] = 1;
              colToSpan[i][j] = 1;
              centerAlign[i][j] = CenterAlign.None;
            }
          }

          let shouldSkip: Record<string, boolean> = {};
          for (let i = 0; i < rowNumbers; i += 1) {
            const fileUrl = section.rows?.[i]?.cta_file?.url || "";
            const url = section.rows?.[i]?.cta_link || "";
            const ctaText = section.rows?.[i]?.cta_text || "";

            buttonRows[i] = {
              icon: section.rows?.[i]?.cta_icon,
              link:
                fileUrl.length > 0
                  ? replaceContentStackURL(fileUrl)
                  : resolveAbsoluteUrl(url, i18nContext),
              isButtonRow: section.rows?.[i]?.cta_row,
              text: ctaText,
              openInNewTab: section.rows?.[i]?.open_in_new_tab,
              variant: section.rows?.[i]?.cta_row_variant || "icon-left",
            };

            for (let j = 0; j < colNumbers; j += 1) {
              if (section.rows?.[i]?.columns?.[j]?.vertical_center) {
                centerAlign[i][j] |= CenterAlign.Vertical;
              }
              if (section.rows?.[i]?.columns?.[j]?.horizontal_center) {
                centerAlign[i][j] |= CenterAlign.Horizontal;
              }

              if (shouldSkip[`${i},${j}`]) continue;
              let rowSpan = section.rows?.[i]?.columns?.[j]?.row_span;
              let colSpan = section.rows?.[i]?.columns?.[j]?.column_span;

              if (rowSpan > 0) {
                rowToSpan[i][j] = rowSpan;
                for (let rowIdx = i + 1; rowIdx < i + rowSpan; rowIdx += 1) {
                  rowToSpan[rowIdx][j] = -1;
                  shouldSkip[`${rowIdx},${j}`] = true;
                }
              }
              if (colSpan > 0) {
                colToSpan[i][j] = colSpan;
                for (let colIdx = j + 1; colIdx < j + colSpan; colIdx += 1) {
                  colToSpan[i][colIdx] = -1;
                  shouldSkip[`${i},${colIdx}`] = true;
                }
              }
              if (rowSpan > 0 && colSpan > 0) {
                for (let rowIdx = i + 1; rowIdx < i + rowSpan; rowIdx += 1) {
                  for (let colIdx = j + 1; colIdx < j + colSpan; colIdx += 1) {
                    colToSpan[rowIdx][colIdx] = -1;
                    rowToSpan[rowIdx][colIdx] = -1;
                    shouldSkip[`${rowIdx},${colIdx}`] = true;
                  }
                }
              }
            }
          }

          return {
            rowSpanConfig: rowToSpan,
            colSpanConfig: colToSpan,
            centerAlign,
            buttonRows,
          };
        })(),
        rows:
          section?.rows?.map(
            (row) =>
              row?.columns?.map(
                (c) =>
                  resolveAbsoluteUrlInParagraph(c?.content, i18nContext) || ""
              ) || []
          ) || [],
      })) || [],
  };
}
