import { Maybe } from "@types";

export interface CSTableContentProps {
  data: {
    title: string;
    description: string;
    footnote: string;
    table: TableContent[];
    headerVisible?: boolean;
  };
}

export interface TableContent {
  stretch_width: boolean;
  use_sticky_column: boolean;
  use_sticky_row: boolean;
  center_alignment: boolean;
  design_settings?: DesignSettings;
  header_multirows?: {
    rows: {
      cells: {
        column_width: string;
        column_span: number;
        row_span: number;
        vertical_center: boolean;
        horizontal_center: boolean;
        content: string;
      }[];
    }[];
  };
  headers: {
    column_width: string;
    column_span: number;
    vertical_center: boolean;
    horizontal_center: boolean;
    content: string;
  }[];
  sections: {
    section_title: string;
    rows: {
      cta_row: boolean;
      cta_link?: string;
      cta_file?: { url: string };
      cta_icon: string;
      cta_text?: string;
      open_in_new_tab: boolean;
      cta_row_variant?: "icon-left" | "icon-right";
      columns: {
        content: string;
        row_span: number;
        column_span: number;
        horizontal_center: boolean;
        vertical_center: boolean;
      }[];
    }[];
  }[];
}

interface TableProps {
  data: TableData;
  isLoading: boolean;
  headerVisible?: boolean;
  extraHeight?: number;
  showNavigation?: boolean;
  tablePosition?: string;
}

export enum CenterAlign {
  None = 0,
  Vertical = 1,
  Horizontal = 2,
}

type TableSection = {
  title?: Maybe<string>;
  rowSpanConfig?: number[][];
  colSpanConfig?: number[][];
  centerAlign?: CenterAlign[][];
  buttonRows?: {
    isButtonRow: boolean;
    icon: string;
    link: string;
    text: string;
    openInNewTab: boolean;
    variant: "icon-left" | "icon-right";
  }[];
  rows: string[][];
};

export interface TableColumnWidth {
  columnIndex: number;
  columnWidth?: Maybe<string>;
}

export interface TableData {
  stretchWidth: boolean;
  stickyColumn: boolean;
  stickyRow: boolean;
  centerAlignment: boolean;
  columnWidths?: Maybe<TableColumnWidth[]>;
  headers: {
    centerAlign: CenterAlign;
    name: string;
  }[];
  headerContents: string[][];
  headerRowSpan?: number[][];
  headerColSpan?: number[][];
  headerCenterAlign?: CenterAlign[][];
  sections?: Maybe<TableSection[]>;
  designSettings: DesignSettings;
}

export enum DesignSettings {
  Default = "default",
  VariantOne = "variant_1",
}

